<!-- 新增敏感字 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="60%"
    @click="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div style="display: flex; align-items: center; margin-bottom: 2%">
      <div style="border-left: 4px solid #556bff">&nbsp;</div>
      <div class="search-title">企业信息</div>
    </div>
    <ax-form ref="form" :formBuilder="formBuilder"> </ax-form>
    <div style="display: flex; align-items: center; margin-bottom: 2%">
      <div style="border-left: 4px solid #556bff">&nbsp;</div>
      <div class="search-title">岗位信息</div>
    </div>
    <ax-form ref="form1" :formBuilder="formBuilder1"> </ax-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="onSubmit"
        style="background-color: #556bff"
        :loading="saveType"
        >保 存</el-button
      >
      <el-button @click="onCancel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import api from "./api";
const formList = [
  {
    label: "单位名称",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入话题名称" }],
  },
  {
    label: "联系人",
    type: "",
    model: "residentName1",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入话题名称" }],
  },
  {
    label: "联系方式",
    type: "",
    model: "residentName2",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入话题名称" }],
  },
];
const formList1 = [
  {
    label: "岗位名称",
    type: "",
    model: "residentName1",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入岗位名称" }],
  },
  {
    label: "招聘人数",
    type: "inputNumber",
    model: "residentName2",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 12 },
  },
  {
    label: "薪资待遇",
    type: "inputNumber",
    model: "residentName3",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入薪资待遇" }],
  },
  {
    label: "招聘要求",
    type: "textarea",
    model: "residentName4",
    options: { allowClear: true, placeholder: "请输入", maxLength: 800 ,minRows: 4},
    col: { span: 24 },
  },
  {
    label: "岗位职责",
    type: "textarea",
    model: "residentName5",
    options: { allowClear: true, placeholder: "请输入", maxLength: 800,minRows: 4 },
    col: { span: 24 },
  },
];
export default {
  components: {},
  data() {
    return {
      api,
      visible: false,
      saveType: false,
      title: "",
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      isEnabled: "",
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
      this.title = record.title;
      this.showComments = false;
    },
    onSubmit() {},
    //关闭弹窗
    onCancel() {
      this.visible = false;
      this.$emit("refsh");
    },
    closeDialog() {
      this.visible = false;
      this.$emit("refsh");
    },
  },
};
</script>
    
<style lang="less" scoped>
.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
/* 穿透区域 */
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__body {
  padding: 10px 40px;
}
/deep/.ant-switch-checked {
  background-color: #2a5cff;
}
</style>
    